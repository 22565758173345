export class Endpoints {

  static baseUrl = '/api';

  static readonly customers = '/v1/customers';
  static readonly products = '/v1/products';
  static readonly invoices = '/v1/invoices';
  static readonly attachments = '/v1/attachments';
  static readonly masterdata = '/v1/masterdata';
  static readonly mailSettings = '/v1/mail-settings';
  static readonly outstandingitems = '/v1/outstanding-items';
  static readonly recurringitems = '/v1/recurring-items';
  static readonly vatpositions = '/v1/masterdata/vatpositions';
  static readonly repeattypes = '/v1/masterdata/repeattypes';
  static readonly paymenttypes = '/v1/masterdata/paymenttypes';
  static readonly unityoptions = '/v1/masterdata/unityoptions';
  static readonly bookholdpositions = '/v1/masterdata/bookholdpositions';
  static readonly reports = '/v1/reports/outstanding-items';

}
